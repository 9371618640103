/*
 * @Description:
 * @Version: 1.0
 * @Autor: silencc
 * @Date: 2020-11-13 16:45:33
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2021-11-04 09:54:34
 */

/**
     * ruleTypes: 规则类型
     * genRules： rule 生成函数
     * ctx： Vue实例
     */
import { genModels } from '@/plugins/widget/c-form/rules';
import { eduLevel, payLevel, getType } from '@/utils/options';

export var models = genModels(function (genRules, ctx) {return [
  {
    span: 12,
    kname: 'userAccount',
    itemProps: {
      label: '用户账号：',
      rules: [genRules('require'), genRules('userAccount')] },

    component: {
      props: {
        maxlength: 12 } },


    readOnlyFn: function readOnlyFn() {
      return ctx.edit;
    } },

  {
    span: 12,
    kname: 'userAccount',
    itemProps: {
      label: '登录密码：' },

    component: {
      name: 'text' },

    valueFilter: function valueFilter() {
      return 123456789;
    } },

  {
    span: 12,
    kname: 'roleId',
    /*
                     筛选值
                     filterFn(v) {
                       return v.id
                     },
                     showFn(v) {
                     隐藏指定字段
                       console.log(1, v)
                       return v === ""
                     },*/
    itemProps: {
      label: '选择角色：',
      rules: [genRules('require')] },

    component: {
      name: 'selector',
      props: {
        filterable: true,
        type: 'roles',
        disableStatus: 1 } } },



  {
    span: 12,
    kname: 'phone',
    itemProps: {
      label: '电话：',
      rules: [genRules('require'), genRules('phone')] },

    component: {
      props: {
        maxlength: 11 } } },



  {
    span: 12,
    kname: 'userName',
    itemProps: {
      label: '用户姓名：',
      rules: [genRules('require'), genRules(function (v) {
        return v.length <= 4 && v.length >= 2;
      }, '', '请输入2-4位')] },

    component: {
      props: {
        maxlength: 4 } } }];});





// form 默认值
export var formData = function formData() {return {};};